import React, { useState, useEffect } from 'react';
import image1 from '../images/artgrassandpaving.jpg';
import image2 from '../images/mushroomtree2.jpg';

import image14 from '../images/image14.jpg';
import image21 from '../images/image21.jpg';
import slideshow from '../images/slideshow.jpg';
import slideshow1 from '../images/slideshow1.jpg';
import slideshow2 from '../images/slideshow2.jpg';
import slideshow3 from '../images/slideshow3.jpg';
import slideshow4 from '../images/slideshow4.jpg';
import slideshow5 from '../images/slideshow5.jpg';


const Slideshow = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const images = [image1, image14, image21, image2, slideshow, slideshow1, slideshow2, slideshow3, slideshow4, slideshow5];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div data-testid="slideshow" className="slideshow-container">
      {images.map((image, index) => (
        <img
        key={index}
        className={index === slideIndex ? 'slide active' : 'slide'}
        src={image}
        alt={`Slide ${index}`}
      />
      ))}
    </div>
  );
};

export default Slideshow;