import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import decking from '../../images/decking.jpg';
import decking1 from '../../images/decking1.jpg';
import decking2 from '../../images/Decking2.jpeg'


const Decking = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Decking</h1>
        <p>
          Decking involves the installation of outdoor platforms made from various materials, such as wood, composite, or PVC. It provides a versatile and functional space for outdoor activities, relaxation, and entertainment.
        </p>
        <p>
          Whether you want to enhance your backyard with a stylish and durable deck or create an outdoor extension of your living space, professional decking installation can help you achieve the desired ambiance and functionality.
        </p>
      </div>

      <div className="image-grid">
      <img src={decking} alt="Deck Design" />
        <img src={decking1} alt="Custom Deck Design" />
        <img src={decking2} alt="Durable Decking Surface" />
      </div>
      <br />
      <h2>Key Elements of Decking</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Custom Designs</h3>
          <ul>
            <li>Custom Designs: Creation of unique layouts, patterns, and finishes tailored to your preferences and outdoor environment.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Durable Surface</h3>
          <ul>
            <li>Durable Surface: Decking materials are chosen for their resilience against weathering, rot, and insect damage, ensuring long-term durability and performance.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Low Maintenance</h3>
          <ul>
            <li>Low Maintenance: Decking surfaces require minimal upkeep, with options available for easy cleaning and resistance to staining, fading, and warping.</li>
          </ul>
        </div>
      </div>
      <br />
      <h2>Benefits of Professional Decking</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Aesthetics</h3>
          <ul>
            <li>Enhanced Aesthetics: Well-designed decks add beauty and character to outdoor spaces, enhancing the overall appeal and value of your property.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Environmental Benefits</h3>
          <ul>
            <li>Environmental Benefits: Sustainable decking materials and construction practices promote eco-friendliness and reduce environmental impact.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Increased Property Value</h3>
          <ul>
            <li>Increased Property Value: Professionally installed decks can boost the marketability and resale value of your property, attracting potential buyers and investors.</li>
          </ul>
        </div>
      </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      <br />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default Decking;