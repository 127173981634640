import React, { useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    mobile: '',
    message: ''
  });

  const [status, setStatus] = useState('');
  const [statusType, setStatusType] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name) {
      formErrors.name = 'Name is required';
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email address is invalid';
      isValid = false;
    }
    if (!formData.subject) {
      formErrors.subject = 'Subject is required';
      isValid = false;
    }
    if (!formData.message) {
      formErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post('https://formspree.io/f/moqggrep', formData);
        if (response.status === 200) {
          setStatus('Email sent successfully!');
          setStatusType('success');
          setFormData({ name: '', email: '', subject: '', mobile: '', message: '' });
        } else {
          setStatus('Failed to send email. Please try again.');
          setStatusType('error');
        }
      } catch (error) {
        setStatus('Failed to send email. Please try again.');
        setStatusType('error');
      }
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-form">
        <h1>Contact Us</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            {errors.subject && <span className="error-message">{errors.subject}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <span className="error-message">{errors.message}</span>}
          </div>
          <button type="submit">Send</button>
          {status && <p className={`status-message ${statusType}`}>{status}</p>}
        </form>
      </div>
      <div className="contact-section container">
        <div className="contact-info">
          <h2>Our Contact Information</h2>
          <p>Mobile: 07549239414</p>
          <p>Email: jordymoore95@gmail.com</p>
          <div className="social-media-icons">
            <a
              href="https://www.facebook.com/braeparklandscapesni/?locale=en_GB"
              data-testid="facebook-link"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/braeparklandscapesni/"
              data-testid="instagram-link"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;