import React from 'react';
import { Link } from 'react-router-dom';
import Slideshow from './SlideShow';
import ContactSection from './ContactSection';
import Footer from './Footer';



const Home = () => {
  return (
    <div className="home-container">
        <Slideshow />

        {/* About Us Section */}
        <br></br>
        <h2>About Us</h2>
        <div className="about-section container">
            <div className="content-wrapper">
                <div className="text-container">
                    <h2>Hi!</h2>
                    <p>Great to meet you! We at Braepark landscapes want to make your garden and
                        landscaping dreams come true. We have a range of expertise in garden
                        maintenance and design. Come have a look at what we do in our projects, or
                        send us a message in contact. Look forward to making your garden your dream space!
                    </p>
                </div>
            </div>
        </div>

        {/* Services Section */}
        <br></br>
        <h2>Our Services</h2>
        <div className="services-section container">
            <div className="row">
            <Link to="/services/landscape-design" className="icon-link">
                <div className="icon-container">
                    <i className="fas fa-tree"></i>
                    <p>Landscape Design</p>
                </div>
            </Link>
            <Link to="/services/garden-maintenance" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-leaf"></i>
                    <p>Garden Maintenance</p>
                </div>
            </Link>
            <Link to="/services/hedge-cutting" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-cut"></i>
                    <p>Hedge Cutting</p>
                </div>
            </Link>
            </div>
    
            <div class="row">
            <Link to="/services/paving" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-road"></i>
                    <p>Paving</p>
                </div>
            </Link>
            <Link to="/services/fencing" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-border-all"></i>
                    <p>Fencing</p>
                </div>
            </Link>
            <Link to="/services/resin-bound" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-layer-group"></i>
                    <p>Patio Resin</p>
                </div>
            </Link>
            </div>
        </div>

        
        {/* Contact Section */}
        <ContactSection />

        {/* Footer Section */}
        <Footer />
    </div>
  );
};

export default Home;



