import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import artificalgrassbefore from '../../images/artificialgrassbefore.jpg';
import artificialgrassafter from '../../images/artificialgrassafter.jpg';
import artificialgrass2 from '../../images/artificialgrass2.jpg';
import artandgrasspaving from '../../images/artgrassandpaving.jpg';
import artificialgrass1 from '../../images/artificialgrass1.jpg';
import image21 from '../../images/image21.jpg';

const ArtificialGrass = () => {
  return (
      <div className="services-container">
          <div className="intro-box">
              <h1>Artificial Grass</h1>
              <p>
                  Artificial grass involves the installation of synthetic turf for outdoor spaces, offering a lush and green alternative to natural grass. It provides a durable, low-maintenance solution for residential, commercial, and recreational areas.
              </p>
              <p>
                  Whether you're seeking a vibrant lawn without the hassle of mowing and watering or looking to enhance the aesthetics of your outdoor space with year-round greenery, professional artificial grass installation can meet your landscaping needs.
              </p>
          </div>

          <div className="image-grid">
              <img src={artificalgrassbefore} alt="Artificial Grass Before" />
              <img src={artificialgrassafter} alt="Artificial Grass After" />
              <img src={artificialgrass2} alt="Artificial Grass" />
              <img src={artandgrasspaving} alt="Artificial Grass and Paving" />
              <img src={artificialgrass1} alt="Artificial Grass" />
              <img src={image21} alt="Artificial Grass" />
          </div>
          <br />
          <h2>Key Elements of Artificial Grass</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Custom Designs</h3>
                  <ul>
                      <li>Custom Designs: Tailored artificial turf solutions to match your desired aesthetic, including different blade lengths, colors, and textures.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Durable Surface</h3>
                  <ul>
                      <li>Durable Surface: Artificial grass offers a resilient and long-lasting outdoor surface that can withstand heavy foot traffic, extreme weather conditions, and pet activity.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Low Maintenance</h3>
                  <ul>
                      <li>Low Maintenance: Synthetic turf requires minimal upkeep, including occasional brushing and rinsing, to keep it looking fresh and vibrant throughout the year.</li>
                  </ul>
              </div>
          </div>
          <br />
          <h2>Benefits of Artificial Grass</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Enhanced Aesthetics</h3>
                  <ul>
                      <li>Enhanced Aesthetics: Artificial grass provides a lush and uniform appearance year-round, enhancing the visual appeal of residential landscapes, commercial properties, and recreational areas.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Environmental Benefits</h3>
                  <ul>
                      <li>Environmental Benefits: Synthetic turf conserves water by eliminating the need for irrigation, reduces chemical usage from fertilizers and pesticides, and minimizes carbon emissions associated with lawn maintenance equipment.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Increased Property Value</h3>
                  <ul>
                      <li>Increased Property Value: Well-maintained artificial grass can enhance the curb appeal and marketability of your property, attracting potential buyers and increasing its resale value.</li>
                  </ul>
              </div>
          </div>
          <br />
          <div className="view-more-button-container">
              <Link to="/services" className="view-more-button">View More Services</Link>
          </div>
          <br />
          <ContactSection />
          <Footer />
      </div>
  );
};

export default ArtificialGrass;