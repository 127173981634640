import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import mossbrushing from '../../images/mossbrushing.jpg';
import driveway from '../../images/driveway.jpg';
import powerwash from '../../images/powerwash.jpg';
import powerwash2 from '../../images/powerwash2.jpg';
import powerwash3 from '../../images/powerwash3.jpg';
import actionpowerwash from '../../images/actionpowerwash.jpg';

const OutdoorCleaning = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Outdoor Cleaning</h1>
        <p>
          Outdoor cleaning involves the revitalization and maintenance of outdoor surfaces, such as patios, walkways, and driveways. It encompasses various techniques to keep these areas clean, attractive, and functional.
        </p>
        <p>
          Whether you want to remove dirt, grime, moss, or other contaminants from your outdoor living spaces, professional outdoor cleaning services can help restore the beauty and functionality of your property.
        </p>
      </div>

      <div className="image-grid">
        <img src={mossbrushing} alt="Moss Brushing" />
        <img src={driveway} alt="Driveway Cleaning" />
        <img src={powerwash} alt="Power Washing" />
        <img src={powerwash2} alt="Cleaned Patio" />
        <img src={powerwash3} alt="Environmentally Friendly Cleaning" />
        <img src={actionpowerwash} alt="Power Washing Action" />
      </div>
      <br />
      <h2>Key Elements of Outdoor Cleaning</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Moss Brushing</h3>
          <ul>
            <li>Moss Brushing: Removal of moss and algae from outdoor surfaces, preventing slippery conditions and improving safety.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Driveway Cleaning</h3>
          <ul>
            <li>Driveway Cleaning: Deep cleaning of driveways to eliminate stains, oil spots, and other blemishes, restoring their appearance and prolonging their lifespan.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Power Washing</h3>
          <ul>
            <li>Power Washing: High-pressure washing to remove stubborn dirt, grime, and debris from various outdoor surfaces, including decks, fences, and siding.</li>
          </ul>
        </div>
      </div>
      <br />
      <h2>Benefits of Professional Outdoor Cleaning</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Appearance</h3>
          <ul>
            <li>Enhanced Appearance: Professional outdoor cleaning enhances the visual appeal of your property, making it more inviting and enjoyable for residents and guests.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Environmentally Friendly</h3>
          <ul>
            <li>Environmentally Friendly: Many outdoor cleaning techniques utilize eco-friendly products and methods, minimizing harm to the environment while maintaining cleanliness.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Protects Property Value</h3>
          <ul>
            <li>Protects Property Value: Regular outdoor cleaning helps preserve the integrity and value of your property, ensuring it remains attractive to potential buyers and tenants.</li>
          </ul>
        </div>
      </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      <br />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default OutdoorCleaning;