import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import fencing from '../../images/slideshow.jpg';
import blackfence from '../../images/blackfence.jpg';
import fencing2 from '../../images/Fencing2.jpeg';
import fencing3 from '../../images/Fencing3.jpeg';
import fencing4 from '../../images/Fencing4.jpeg';
import fencing5 from '../../images/Fencing5.jpeg';

const Fencing = () => {
  return (
      <div className="services-container">
          <div className="intro-box">
          <h1>Fencing</h1>
          <p>
            Fencing involves the installation of barriers or enclosures around outdoor spaces to provide privacy, security, and aesthetic enhancement. It serves various purposes, including defining property boundaries, enclosing gardens or yards, and protecting against intruders or wildlife.
          </p>
          <p>
            Whether you're looking to enhance the security of your property, add privacy to your outdoor living spaces, or improve the visual appeal of your landscape, professional fencing services can help you choose the right fencing materials and designs to meet your needs.
          </p>
          </div>

          <div className="image-grid">
              <img src={fencing} alt="Fencing" />
              <img src={blackfence} alt="Black Fence" />
              <img src={fencing2} alt="Fencing2" />
              <img src={fencing3} alt="Fencing3" />
              <img src={fencing4} alt="Fencing4" />
              <img src={fencing5} alt="Fencing5" />
          </div>
          <br />
          <h2>Key Elements of Fencing</h2>
          <div className="content-grid">
              <div className="content-box">
                <h3>Privacy Fences</h3>
              <ul>
                <li>Installation of tall and solid fences to create secluded and intimate outdoor spaces.</li>
              </ul>
              </div>
              <div className="content-box">
                <h3>Security Fences</h3>
              <ul>
                <li>Construction of sturdy and durable fences to protect properties from unauthorized access and intruders.</li>
              </ul>
              </div>
              <div className="content-box">
              <h3>Decorative Fences</h3>
            <ul>
              <li>Installation of aesthetically pleasing fences to enhance the visual appeal of outdoor spaces.</li>
            </ul>
              </div>
          </div>
          <br />
          <h2>Benefits of Professional Fencing</h2>
          <div className="content-grid">
              <div className="content-box">
              <h3>Enhanced Security</h3>
            <ul>
              <li>Well-designed and properly installed fences provide a barrier against intruders and improve the overall security of properties.</li>
            </ul>
              </div>
              <div className="content-box">
              <h3>Improved Privacy</h3>
            <ul>
              <li>Privacy fences create secluded outdoor spaces where you can relax and enjoy without worrying about prying eyes.</li>
            </ul>
              </div>
              <div className="content-box">
              <h3>Aesthetic Enhancement</h3>
            <ul>
              <li>Decorative fences add charm and character to outdoor environments, enhancing the overall visual appeal of properties.</li>
            </ul>
              </div>
          </div>
          <br />
          <div className="view-more-button-container">
              <Link to="/services" className="view-more-button">View More Services</Link>
          </div>
          <br />
          <ContactSection />
          <Footer />
      </div>
  );
};

export default Fencing;
