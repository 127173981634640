import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import grasscutter from '../../images/grasscutter.jpg';
import maintenance from '../../images/maintenance.jpg';
import lawnmower from '../../images/lawnmower.jpg';
import spray from '../../images/spraything.jpg';
import lawnmower2 from '../../images/lawnmower2.jpg';
import redgrasscutter from '../../images/redgrasscutter.jpg';


const GardenMaintenance = () => {
  return (
      <div className="services-container">
          <div className="intro-box">
              <h1>Garden Maintenance</h1>
              <p>
                  Garden maintenance involves the care and upkeep of outdoor spaces to ensure they remain healthy, beautiful, and functional. It encompasses various tasks, including lawn care, plant pruning, pest control, and general landscape maintenance.
              </p>
              <p>
                  Regular garden maintenance is essential for preserving the aesthetic appeal of your outdoor space and promoting the health and growth of plants. Whether you need seasonal clean-ups, routine lawn mowing, or specialized care for specific plants, professional garden maintenance services can help keep your garden looking its best year-round.
              </p>
          </div>

          <div className="image-grid">
              <img src={grasscutter} alt="Grass Cutter" />
              <img src={maintenance} alt="Maintenance" />
              <img src={lawnmower} alt="Lawn Mower" />
              <img src={spray} alt="Spray" />
              <img src={lawnmower2} alt="Lawn Mower 2" />
              <img src={redgrasscutter} alt="Red Grass Cutter" />
          </div>
          <br />
          <h2>Key Elements of Garden Maintenance</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Lawn Care</h3>
                  <ul>
                      <li>Lawn Care: Mowing, fertilizing, aerating, and overseeding to maintain healthy and vibrant grass.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Plant Pruning</h3>
                  <ul>
                      <li>Plant Pruning: Trimming and shaping of shrubs, trees, and other plants to promote growth and aesthetics.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Pest Control</h3>
                  <ul>
                      <li>Pest Control: Identification and treatment of pests and diseases to prevent damage to plants.</li>
                  </ul>
              </div>
          </div>
          <br />
          <h2>Benefits of Professional Garden Maintenance</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Enhanced Beauty</h3>
                  <ul>
                      <li>Enhanced Beauty: Well-maintained gardens enhance the aesthetic appeal of your property and create a welcoming environment.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Increased Property Value</h3>
                  <ul>
                      <li>Increased Property Value: A beautifully landscaped garden can significantly increase the value of your property.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Healthy Environment</h3>
                  <ul>
                      <li>Healthy Environment: Proper garden maintenance promotes plant health and biodiversity, creating a thriving ecosystem.</li>
                  </ul>
              </div>
          </div>
          <br />
          <div className="view-more-button-container">
              <Link to="/services" className="view-more-button">View More Services</Link>
          </div>
          <br />
          <ContactSection />
          <Footer />
      </div>
  );
};

export default GardenMaintenance;