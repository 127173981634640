import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import hedgecut from '../../images/hedgecut.jpg';
import hedgecut2 from '../../images/hedgecut2.jpg';
import hedgecut5 from '../../images/hedgecut5.jpg';
import mushroomtree from '../../images/mushroomtree.jpg';
import mushroomtree2 from '../../images/mushroomtree2.jpg';
import actionhedgecut from '../../images/actionhedgecut.jpg';

const HedgeCutting = () => {
  return (
      <div className="services-container">
          <div className="intro-box">
              <h1>Hedge Cutting</h1>
              <p>
                  Hedge cutting involves the trimming and shaping of hedges to maintain their appearance, health, and functionality. Proper hedge maintenance is essential for promoting dense growth, preventing overgrowth, and enhancing the overall aesthetics of outdoor spaces.
              </p>
              <p>
                  Regular hedge cutting helps define property boundaries, create privacy screens, and reduce the risk of pest infestations. Whether you have formal hedges, informal shrubs, or topiary designs, professional hedge cutting services can ensure your hedges remain neat, tidy, and healthy throughout the year.
              </p>
          </div>

          <div className="image-grid">
              <img src={hedgecut} alt="Hedge Cutting" />
              <img src={hedgecut2} alt="Hedge Cutting 2" />
              <img src={hedgecut5} alt="Hedge Cutting 5" />
              <img src={mushroomtree} alt="Mushroom Tree" />
              <img src={mushroomtree2} alt="Mushroom Tree 2" />
              <img src={actionhedgecut} alt="Action Hedge Cutting" />
          </div>
          <br />
          <h2>Key Elements of Hedge Cutting</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Trimming and Shaping</h3>
                  <ul>
                      <li>Trimming and Shaping: Precision trimming of hedges to maintain desired shapes and sizes.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Height Management</h3>
                  <ul>
                      <li>Height Management: Cutting hedges to appropriate heights for privacy, aesthetics, and sunlight exposure.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Health Assessment</h3>
                  <ul>
                      <li>Health Assessment: Inspecting hedges for signs of disease, pest infestation, or structural issues.</li>
                  </ul>
              </div>
          </div>
          <br />
          <h2>Benefits of Professional Hedge Cutting</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Enhanced Property Appearance</h3>
                  <ul>
                      <li>Enhanced Property Appearance: Well-maintained hedges improve the overall look and curb appeal of your property.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Healthy Hedges</h3>
                  <ul>
                      <li>Healthy Hedges: Regular trimming promotes dense growth and reduces the risk of disease and pest infestation.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Improved Property Security</h3>
                  <ul>
                      <li>Improved Property Security: Well-maintained hedges act as natural barriers, enhancing privacy and security.</li>
                  </ul>
              </div>
          </div>
          <br />
          <div className="view-more-button-container">
              <Link to="/services" className="view-more-button">View More Services</Link>
          </div>
          <br />
          <ContactSection />
          <Footer />
      </div>
  );
};

export default HedgeCutting;