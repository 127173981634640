import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import afterflowerbed from '../../images/afterflowerbed.jpg';
import planting from '../../images/planting.jpg';
import planting8 from '../../images/planting8.jpg';
import planting3 from '../../images/planting3.jpg';
import planting4 from '../../images/planting4.jpg';
import planting5 from '../../images/planting5.jpg';

const Planting = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Planting</h1>
        <p>
          Planting involves the selection, arrangement, and maintenance of vegetation in outdoor spaces to enhance their beauty, functionality, and environmental benefits. It adds natural elements and greenery to your surroundings, creating a vibrant and inviting atmosphere.
        </p>
        <p>
          Whether you're interested in creating a colorful flowerbed, a lush garden, or a sustainable landscape, professional planting services can help you achieve your desired aesthetic and practical goals.
        </p>
      </div>

      <div className="image-grid">
        <img src={planting} alt="Planting" />
        <img src={planting8} alt="Custom Plant Designs" />
        <img src={planting3} alt="Selection and Preparation" />
        <img src={afterflowerbed} alt="Maintenance" />
        <img src={planting4} alt="Enhanced Aesthetics" />
        <img src={planting5} alt="Environmental Benefits" />
      </div>
      <br />
      <h2>Key Elements of Planting</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Custom Designs</h3>
          <ul>
            <li>Custom Designs: Creation of unique plant arrangements, combinations, and layouts tailored to your preferences, climate, and outdoor environment.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Selection and Preparation</h3>
          <ul>
            <li>Selection and Preparation: Expert advice on choosing suitable plants, soil, and planting techniques to ensure healthy growth and longevity of your garden or landscape.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Maintenance</h3>
          <ul>
            <li>Maintenance: Regular care and upkeep, including watering, fertilizing, pruning, and pest control, to preserve the beauty and health of your plants and landscape.</li>
          </ul>
        </div>
      </div>
      <br />
      <h2>Benefits of Professional Planting</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Aesthetics</h3>
          <ul>
            <li>Enhanced Aesthetics: Well-planned and maintained planting arrangements enhance the visual appeal and charm of your outdoor spaces, creating a welcoming and relaxing environment.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Environmental Benefits</h3>
          <ul>
            <li>Environmental Benefits: Planting trees, shrubs, and flowers contributes to air purification, soil stabilization, and biodiversity conservation, promoting a healthier and more sustainable ecosystem.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Increased Property Value</h3>
          <ul>
            <li>Increased Property Value: Well-designed and maintained landscapes enhance the curb appeal and marketability of your property, increasing its overall value and desirability.</li>
          </ul>
        </div>
      </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      {/* Contact Section */}
      <ContactSection />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Planting;
