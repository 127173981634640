import React from 'react';
import ContactSection from './ContactSection';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

const Services = () => {
  return (
    <div className="services-container">
        {/* Services Section */}
        <h2>Our Services</h2>
        <div className="services-section-container">
            <div className="row">
            <Link to="/services/landscape-design" className="icon-link">
                <div className="icon-container">
                    <i className="fas fa-tree"></i>
                    <p>Landscape Design</p>
                </div>
            </Link>
            <Link to="/services/garden-maintenance" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-leaf"></i>
                    <p>Garden Maintenance</p>
                </div>
              </Link>
              <Link to="/services/hedge-cutting" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-cut"></i>
                    <p>Hedge Cutting</p>
                </div>
              </Link>
            </div>
    
            <div class="row">
            <Link to="/services/paving" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-road"></i>
                    <p>Paving</p>
                </div>
            </Link>
            <Link to="/services/fencing" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-border-all"></i>
                    <p>Fencing</p>
                </div>
            </Link>
            <Link to="/services/resin-bound" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-layer-group"></i>
                    <p>Resin Bound</p>
                </div>
            </Link>
            </div>

            <div class="row">
            <Link to="/services/outdoor-cleaning" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-brush"></i>
                    <p>Outdoor Cleaning</p>
                </div>
            </Link>
            <Link to="/services/decking" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-th"></i>
                    <p>Decking</p>
                </div>
            </Link>
            <Link to="/services/planting" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-seedling"></i>
                    <p>Planting</p>
                </div>
            </Link>
            </div>

            <div class="row">
            <Link to="/services/turf-seeded-lawn" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-seedling"></i>
                    <p>Turf & Seeded Lawn</p>
                </div>
            </Link>
            <Link to="/services/artificial-grass" className="icon-link">
                <div class="icon-container">
                    <i className="fas fa-leaf"></i>
                    <p>Artificial Grass</p>
                </div>
            </Link>
            </div>
        </div>

        {/* Contact Section */}
        <ContactSection />

        {/* Footer Section */}
        <Footer />
      </div>
  );
};

export default Services;