import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import afterflowerbed from '../../images/afterflowerbed.jpg';
import image14 from '../../images/image14.jpg';
import image21 from '../../images/image21.jpg';
import paving3 from '../../images/paving3.jpg';
import slideshow from '../../images/slideshow.jpg';
import slideshow1 from '../../images/slideshow1.jpg';



const LandscapeDesign = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Landscape Design</h1>
        <p>
          Landscape design is the art and science of creating outdoor spaces that are both functional and aesthetically pleasing. It involves careful planning and consideration of factors such as site conditions, client preferences, and environmental sustainability.
        </p>
        <p>
          A well-designed landscape can enhance the beauty of a property, provide outdoor living spaces for relaxation and recreation, and increase property value. Whether you're looking to create a tranquil garden retreat, a vibrant outdoor entertaining area, or a sustainable landscape that conserves water and supports local wildlife, professional landscape design can help bring your vision to life.
        </p>
      </div>

      <div className="image-grid">
        <img src={afterflowerbed} alt="After Flower Bed" />
        <img src={image14} alt="Landscape Garden" />
        <img src={image21} alt="Artificial Grass" />
        <img src={paving3} alt="Paving 3" />
        <img src={slideshow} alt="Slideshow" />
        <img src={slideshow1} alt="Slideshow 1" />
      </div>
      <br />
      <h2>Key Elements of Landscape Design</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Plants</h3>
          <ul>
            <li>Selection and placement of trees, shrubs, flowers, and other vegetation to create desired effects.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Hardscape Features</h3>
          <ul>
            <li>Design and installation of pathways, patios, decks, retaining walls, and other structural elements.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Outdoor Living Spaces</h3>
          <ul>
            <li>Creation of functional areas for dining, seating, cooking, and other outdoor activities.</li>
          </ul>
        </div>
        </div>
        <div className="center-content-grid">
        <div className="content-box">
          <h3>Water Features</h3>
          <ul>
            <li>Incorporation of ponds, fountains, waterfalls, and other water elements for visual interest and relaxation.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Lighting</h3>
          <ul>
            <li>Installation of landscape lighting to enhance safety, security, and ambiance during evening hours.</li>
          </ul>
        </div>
        </div>
      <br />
      <h2>Benefits of Professional Landscape Design</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Curb Appeal</h3>
          <ul>
            <li>A well-designed landscape can significantly improve the appearance of a property and make a positive first impression.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Increased Property Value</h3>
          <ul>
            <li>Quality landscaping has been shown to increase property value and attract potential buyers.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Improved Functionality</h3>
          <ul>
            <li>Thoughtful design solutions can maximize the use of outdoor space and create areas for relaxation, entertainment, and recreation.</li>
          </ul>
        </div>
        </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      <br />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default LandscapeDesign;