import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './Navigation';
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import LandscapeDesign from './components/services/LandscapeDesign';
import GardenMaintenance from './components/services/GardenMaintenance';
import HedgeCutting from './components/services/HedgeCutting';
import Paving from './components/services/Paving';
import Fencing from './components/services/Fencing';
import ResinBound from './components/services/ResinBound';
import OutdoorCleaning from './components/services/OutdoorCleaning';
import Decking from './components/services/Decking';
import Planting from './components/services/Planting';
import TurfAndSeededLawn from './components/services/TurfSeededLawn';
import ArtificialGrass from './components/services/ArtificialGrass';


function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/landscape-design" element={<LandscapeDesign />} />
          <Route path="/services/garden-maintenance" element={<GardenMaintenance />} />
          <Route path="/services/hedge-cutting" element={<HedgeCutting />} />
          <Route path="/services/paving" element={<Paving />} />
          <Route path="/services/fencing" element={<Fencing />} />
          <Route path="/services/resin-bound" element={<ResinBound />} />
          <Route path="/services/outdoor-cleaning" element={<OutdoorCleaning />} />
          <Route path="/services/decking" element={<Decking />} />
          <Route path="/services/planting" element={<Planting />} />
          <Route path="/services/turf-seeded-lawn" element={<TurfAndSeededLawn />} />
          <Route path="/services/artificial-grass" element={<ArtificialGrass />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
