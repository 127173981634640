import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const ContactSection = () => {
    return (
      <div data-testid="contact-section" className="contact-section container">
        <Link to="/contact">
          <h2>Contact Us</h2>
        </Link>
        <div className="contact-info">
          <p>Email: jordymoore95@gmail.com</p>
          <p>Phone: 07549239414</p>
          <div className="social-media-icons">
          <a href="https://www.facebook.com/braeparklandscapesni/?locale=en_GB" data-testid="facebook-link" ><FaFacebookF /></a>
          <a href="https://www.instagram.com/braeparklandscapesni/" data-testid="instagram-link" ><FaInstagram /></a>
          </div>
        </div>
      </div>
    );
  };
  
  export default ContactSection;