import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoImage from './images/logo_nobg.png'

const Navigation = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    
    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }

 return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
            <img src={logoImage} alt="Logo" className="logo-image" />
        </Link>
        <div className="menu-icon" onClick={handleMobileMenuToggle}>
          <i className={isMobileMenuOpen ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={isMobileMenuOpen ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={handleMobileMenuToggle}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/services" className="nav-links" onClick={handleMobileMenuToggle}>
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={handleMobileMenuToggle}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;



