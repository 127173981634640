import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import turflawn from '../../images/turflawn.jpg';
import turflawn2 from '../../images/turflawn2.jpg';
import stripeturflawn from '../../images/stripeturflawn.jpg';

const TurfAndSeededLawn = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Turf and Seeded Lawn</h1>
        <p>
          Turf and seeded lawn services involve the installation and maintenance of natural grass surfaces in outdoor spaces. Whether you prefer the instant gratification of turf or the more traditional approach of seeding, both options offer lush, green lawns for your enjoyment.
        </p>
        <p>
          Whether you're aiming to transform your backyard into a recreational oasis or enhance your property's curb appeal, professional turf and seeded lawn services can help you achieve a vibrant and healthy lawn.
        </p>
      </div>

      <div className="image-grid">
        <img src={turflawn} alt="Turf Lawn" />
        <img src={turflawn2} alt="Low Maintenance Lawn" />
        <img src={stripeturflawn} alt="Striped Turf Lawn" />
      </div>
      <br />
      <h2>Key Elements of Turf and Seeded Lawn</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Custom Designs</h3>
          <ul>
            <li>Custom Designs: Tailored seeding plans and turf installation to suit your landscape, preferences, and climate conditions.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Durable Surface</h3>
          <ul>
            <li>Durable Surface: Turf and seeded lawns provide resilient and long-lasting outdoor surfaces for various activities and gatherings.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Low Maintenance</h3>
          <ul>
            <li>Low Maintenance: Both turf and seeded lawns require minimal upkeep, including mowing, watering, and fertilizing, to maintain their health and appearance.</li>
          </ul>
        </div>
      </div>
      <br />
      <h2>Benefits of Professional Turf and Seeded Lawn</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Aesthetics</h3>
          <ul>
            <li>Enhanced Aesthetics: Well-maintained turf and seeded lawns enhance the beauty and charm of outdoor spaces, creating a picturesque backdrop for relaxation and recreation.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Environmental Benefits</h3>
          <ul>
            <li>Environmental Benefits: Natural grass surfaces contribute to air purification, soil stabilization, and water absorption, supporting a healthier and more sustainable environment.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Increased Property Value</h3>
          <ul>
            <li>Increased Property Value: Well-maintained turf and seeded lawns enhance the curb appeal and marketability of your property, boosting its overall value and desirability.</li>
          </ul>
        </div>
      </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      {/* Contact Section */}
      <ContactSection />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default TurfAndSeededLawn;