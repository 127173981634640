import React from 'react';

const Footer = () => {
  return (
    <footer data-testid="footer" className="footer">
      <p>&copy; 2024 Braepark Landscapes. All rights reserved.</p>
    </footer>
  );
};

export default Footer;