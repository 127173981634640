import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import resinbound from '../../images/resinbound.jpg';
import resinbound1 from '../../images/resinbound1.jpeg';
import resinbound2 from '../../images/resinbound2.jpeg';


const ResinBound = () => {
  return (
      <div className="services-container">
          <div className="intro-box">
              <h1>Resin Bound</h1>
              <p>
                  Patio resin involves the installation of resin-bound surfaces for outdoor patios, walkways, and driveways. It offers a durable, low-maintenance, and visually appealing alternative to traditional paving materials.
              </p>
              <p>
                  Whether you're looking to revitalize your outdoor living space with a modern and versatile surface or create a seamless transition between indoor and outdoor areas, professional patio resin installation can help you achieve the desired look and functionality.
              </p>
          </div>

          <div className="image-grid">
              <img src={resinbound} alt="Resin Bound Surface" />
              <img src={resinbound1} alt="Resin Bound Pathway" />
              <img src={resinbound2} alt="Resin Bound Driveway" />
          </div>
          <br />
          <h2>Key Elements of Patio Resin</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Custom Designs</h3>
                  <ul>
                      <li>Creation of unique patterns, colors, and textures to match your aesthetic preferences and complement your outdoor environment.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Durable Surface</h3>
                  <ul>
                      <li>Resin-bound surfaces are highly resistant to cracking, fading, and staining, ensuring long-lasting performance and minimal maintenance.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Low Maintenance</h3>
                  <ul>
                      <li>Easy-to-clean and weed-resistant surfaces require minimal upkeep, saving you time and effort in maintenance tasks.</li>
                  </ul>
              </div>
          </div>
          <br />
          <h2>Benefits of Professional Patio Resin</h2>
          <div className="content-grid">
              <div className="content-box">
                  <h3>Enhanced Aesthetics</h3>
                  <ul>
                      <li>Resin-bound surfaces add a sleek and modern touch to outdoor spaces, enhancing the overall visual appeal of your property.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Environmental Benefits</h3>
                  <ul>
                      <li>Porous resin-bound surfaces allow water to drain through, reducing runoff and minimizing the risk of flooding and erosion.</li>
                  </ul>
              </div>
              <div className="content-box">
                  <h3>Increased Property Value</h3>
                  <ul>
                      <li>High-quality resin-bound surfaces can enhance the marketability and value of your property, making it more attractive to potential buyers.</li>
                  </ul>
              </div>
          </div>
          <br />
          <div className="view-more-button-container">
              <Link to="/services" className="view-more-button">View More Services</Link>
          </div>
          <br />
          <ContactSection />
          <Footer />
      </div>
  );
};

export default ResinBound;