import React from 'react';
import ContactSection from '../ContactSection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import progresspaving from '../../images/progresspaving.jpg';
import paving from '../../images/paving.jpg';
import paving2 from '../../images/paving2.jpg';
import progresspaving2 from '../../images/progresspaving2.jpg';
import paving3 from '../../images/paving3.jpg';
import paving4 from '../../images/paving4.jpg';

const Paving = () => {
  return (
    <div className="services-container">
      <div className="intro-box">
        <h1>Paving</h1>
        <p>
          Paving involves the installation of hard surfaces such as driveways, pathways, and patios using various materials like concrete, asphalt, stone, or brick. It plays a crucial role in defining outdoor spaces, providing functionality, and enhancing the aesthetic appeal of properties.
        </p>
        <p>
          Whether you're looking to create a durable driveway, an elegant patio, or a functional pathway, professional paving services can help you choose the right materials and designs to suit your needs and preferences.
        </p>
      </div>

      <div className="image-grid">
        <img src={progresspaving} alt="Driveways" />
        <img src={paving} alt="Patios" />
        <img src={paving2} alt="Pathways" />
        <img src={progresspaving2} alt="Enhanced Property Value" />
        <img src={paving3} alt="Improved Curb Appeal" />
        <img src={paving4} alt="Durable Surfaces" />
      </div>
      <br />
      <h2>Key Elements of Paving</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Driveways</h3>
          <ul>
            <li>Driveways: Installation of durable and visually appealing surfaces for vehicle access and parking.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Patios</h3>
          <ul>
            <li>Patios: Creation of outdoor living spaces for dining, relaxation, and entertainment using decorative paving materials.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Pathways</h3>
          <ul>
            <li>Pathways: Construction of functional walkways and pathways to connect different areas of the property and enhance accessibility.</li>
          </ul>
        </div>
      </div>
      <br />
      <h2>Benefits of Professional Paving</h2>
      <div className="content-grid">
        <div className="content-box">
          <h3>Enhanced Property Value</h3>
          <ul>
            <li>Enhanced Property Value: Well-designed and properly maintained paved surfaces can increase the resale value of your property.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Improved Curb Appeal</h3>
          <ul>
            <li>Improved Curb Appeal: Professionally paved driveways, patios, and pathways enhance the overall aesthetics of your property.</li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Durable Surfaces</h3>
          <ul>
            <li>Durable Surfaces: High-quality paving materials and expert installation ensure long-lasting durability and minimal maintenance.</li>
          </ul>
        </div>
      </div>
      <br />
      <div className="view-more-button-container">
        <Link to="/services" className="view-more-button">View More Services</Link>
      </div>
      {/* Contact Section */}
      <ContactSection />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Paving;